import { forwardRef } from 'react'
import { Link, LinkProps } from 'react-router-dom'

const LinkBehavior = forwardRef<
  HTMLAnchorElement,
  Omit<LinkProps, 'to'> & { href: LinkProps['to'] }
>(({ href, ...other }, ref) => {
  return <Link data-testid="custom-link" ref={ref} to={href} {...other} />
})

export default LinkBehavior
