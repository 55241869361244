export default function interpolate(
  message: string,
  obj: Record<string, string | number> | (string | number)[]
) {
  if (Array.isArray(obj)) {
    return message.replace(/%s/g, () => String(obj.shift()))
  }

  return message.replace(/%\(\w+\)s/g, (match) =>
    String(obj[match.slice(2, -2)])
  )
}
