/* eslint-disable @typescript-eslint/no-explicit-any */
function hasFile(data: any) {
  if (data instanceof File || data instanceof Blob) {
    return true
  }

  if (Array.isArray(data)) {
    for (const item of data) {
      if (hasFile(item)) {
        return true
      }
    }

    return false
  }

  if (typeof data === 'object' && data !== null) {
    for (const key in data) {
      if (hasFile(data[key])) {
        return true
      }
    }

    return false
  }

  return false
}

function isPlainObject(value: any) {
  if (typeof value !== 'object' || value === null || Array.isArray(value)) {
    return false
  }

  const prototype = Object.getPrototypeOf(value)
  return prototype === null || prototype === Object.prototype
}

function convertToFormData(
  formData: FormData,
  value: any,
  recursiveKey = ''
): FormData {
  if (Array.isArray(value) || isPlainObject(value)) {
    return Object.entries(value).reduce((data, [key, value]) => {
      const _key = recursiveKey ? `${recursiveKey}[${key}]` : key
      return convertToFormData(data, value, _key)
    }, formData)
  }

  formData.append(recursiveKey, value)

  return formData
}

export default function prepareBody(data: any) {
  if (hasFile(data)) {
    return convertToFormData(new FormData(), data)
  }

  return data
}
