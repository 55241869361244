import { createTheme } from '@mui/material/styles'

import LinkBehavior from 'common/components/LinkBehavior'
import variables from 'assets/styles/_variables.module.scss'

declare module '@mui/material/styles' {
  interface Palette {
    aquaHaze: Palette['primary']
    mintcream: Palette['primary']
    travertine: Palette['primary']
  }

  interface PaletteOptions {
    aquaHaze?: PaletteOptions['primary']
    mintcream?: PaletteOptions['primary']
    travertine?: PaletteOptions['primary']
  }
}

const theme = createTheme({
  typography: {
    fontFamily: 'Inter, sans-serif',
    fontSize: 14,
    h5: {
      fontSize: '1.375rem',
    },
    subtitle1: {
      lineHeight: 1.5,
    },
  },
  palette: {
    primary: {
      main: variables.primaryMain,
      dark: variables.primaryDark,
      light: variables.primaryLight,
    },
    secondary: {
      main: variables.secondaryMain,
      dark: variables.secondaryDark,
      light: variables.secondaryLight,
    },
    error: {
      main: variables.errorMain,
      dark: variables.errorDark,
      light: variables.errorLight,
    },
    warning: {
      main: variables.warningMain,
      dark: variables.warningDark,
      light: variables.warningLight,
    },
    info: {
      main: variables.infoMain,
      dark: variables.infoDark,
      light: variables.infoLight,
    },
    success: {
      main: variables.successMain,
      dark: variables.successDark,
      light: variables.successLight,
    },
    text: {
      primary: variables.textPrimary,
      secondary: variables.textSecondary,
      disabled: variables.textDisabled,
    },
    aquaHaze: {
      main: variables.aquaHaze,
    },
    mintcream: {
      main: variables.mintcream,
    },
    travertine: {
      main: variables.travertine,
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          textTransform: 'capitalize',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          color: 'inherit',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.common.white,
        }),
      },
    },
    MuiSelect: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontWeight: 500,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardError: ({ theme }) => ({
          backgroundColor: theme.palette.error.light,
          color: variables.error160P,
        }),
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.secondary,
        }),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          textTransform: 'capitalize',
          '&:hover': {
            textDecoration: 'none',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.primary,
        }),
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderTop: `1px solid ${theme.palette.divider}`,
        }),
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: ({ theme }) => ({
          paddingTop: 0,
          paddingBottom: theme.spacing(4),
          paddingLeft: theme.spacing(4),
          paddingRight: theme.spacing(4),
          '& >:not(:first-of-type)': {
            marginLeft: theme.spacing(1.5),
          },
        }),
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        fontSizeSmall: {
          fontSize: '1rem',
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          fontSize: '0.75rem',
          color: variables.primary50P,
          textTransform: 'uppercase',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: ({ theme }) => ({
          backgroundColor: theme.palette.text.primary,
        }),
      },
    },
  },
})

theme.shadows[1] = '0px 1px 3px 0px rgba(0, 0, 0, 0.12)'
theme.shadows[2] =
  '0px 1px 5px 0px rgba(4, 31, 94, 0.12), 0px 2px 2px 0px rgba(4, 31, 94, 0.14), 0px 3px 3px -2px rgba(4, 31, 94, 0.05)'
theme.shadows[3] = '0px 10px 40px 0px rgba(4, 31, 94, 0.10)'
theme.shadows[6] =
  '0px 1px 18px 0px rgba(0, 0, 0, 0.12), 0px 6px 10px 0px rgba(0, 0, 0, 0.14)'

export default theme
