import { useCallback, useState } from 'react'
import { RawAxiosRequestHeaders } from 'axios'
import { FormikHelpers } from 'formik'

import { ErrorType } from 'types'
import api from 'common/services/api'
import prepareBody from 'common/utils/prepareBody'

export const FORM_ERROR = 'general'

export const getFormErrors = (
  err: ErrorType
): { fieldErrors: object; [FORM_ERROR]?: string | string[] | null } => {
  const {
    non_field_errors: nonFieldErrors,
    message,
    ...fieldErrors
  } = err || {}
  return {
    fieldErrors,
    [FORM_ERROR]: nonFieldErrors || message,
  }
}

type SubmitValue<Value, ResponseValue, RequestValue> = {
  data: RequestValue
  headers?: RawAxiosRequestHeaders
  actions: FormikHelpers<Value>
  onSuccess?: (data: ResponseValue) => void
  onError?: (err: object) => void
}

export default function useFormSubmit<
  Value,
  ResponseValue = Value,
  RequestValue = Value,
>(url: string, method: 'post' | 'put' | 'patch' = 'post') {
  const [generalError, setGeneralError] = useState<string | string[] | null>()
  const submitForm = useCallback(
    ({
      data,
      headers,
      actions,
      onSuccess,
      onError,
    }: SubmitValue<Value, ResponseValue, RequestValue>) => {
      setGeneralError(null)
      return api<ResponseValue>({
        url,
        method,
        data: prepareBody(data),
        headers,
      })
        .then((res) => typeof onSuccess === 'function' && onSuccess(res))
        .catch((err) => {
          typeof onError === 'function' && onError(err)
          const { fieldErrors, [FORM_ERROR]: general } = getFormErrors(
            err?.data || err
          )
          actions.setErrors(fieldErrors)
          setGeneralError(general)
        })
        .finally(() => {
          actions.setSubmitting(false)
        })
    },
    [url, method]
  )

  return {
    generalError,
    submitForm,
  }
}
