import { Context, createContext } from 'react'

type TranslationsContextType = {
  languageCode: string
  translations: Record<string, Record<string, string>>
  gettext: (text: string) => string
  pgettext: (id: string, text: string) => string
  ngettext: (singular: string, plural: string, count: number) => string
  npgettext: (
    id: string,
    singular: string,
    plural: string,
    count: number
  ) => string
}

const TranslationsContext: Context<TranslationsContextType> = createContext(
  {} as TranslationsContextType
)

export default TranslationsContext
