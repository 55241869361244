import React, { PropsWithChildren } from 'react'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import query from 'common/services/query'
import Snackbar from 'common/services/snackbar'
import TranslationsProvider from 'common/services/translations'

import theme from './theme'

export default function AppProvider({ children }: PropsWithChildren) {
  return (
    <React.StrictMode>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Snackbar>
              <QueryClientProvider client={query}>
                <TranslationsProvider>{children}</TranslationsProvider>
                <ReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider>
            </Snackbar>
          </LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </React.StrictMode>
  )
}
