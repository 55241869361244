import { QueryClient, QueryFunctionContext } from '@tanstack/react-query'

import api from './api'

const defaultQueryFunction = ({ queryKey }: QueryFunctionContext) => {
  const [key, params = {}] = queryKey
  if (typeof key !== 'string') {
    return
  }

  return api.get(key, { params })
}

const query = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFunction,
      refetchOnWindowFocus: false,
    },
  },
})

export default query
