import { ReactNode, useCallback } from 'react'

import TranslationsContext from './TranslationsContext'

type Props = {
  data?: Record<string, Record<string, string>>
  locale?: string
  children: ReactNode
}

export default function TranslationsProvider({
  data = {},
  locale = 'en',
  children,
}: Props) {
  const translations = data

  const gettext = useCallback(
    (text: string = '') => {
      return translations?.[locale]?.[text] || translations?.en?.[text] || text
    },
    [locale, translations]
  )

  const pgettext = useCallback(
    (id: string, text: string) => {
      return gettext(`${text} ${id}`)
    },
    [gettext]
  )

  const ngettext = useCallback(
    (singular: string, plural: string, count: number) => {
      return count === 1 ? gettext(singular) : gettext(plural)
    },
    [gettext]
  )

  const npgettext = useCallback(
    (id: string, singular: string, plural: string, count: number) => {
      return count === 1 ? pgettext(id, singular) : pgettext(id, plural)
    },
    [pgettext]
  )

  return (
    <TranslationsContext.Provider
      value={{
        languageCode: locale,
        translations,
        gettext,
        pgettext,
        ngettext,
        npgettext,
      }}
    >
      {children}
    </TranslationsContext.Provider>
  )
}
