import { Fragment, useMemo } from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import AddIcon from '@mui/icons-material/Add'

import { useTranslations } from 'common/services/translations'
import { StudyFaqItem } from 'types'

import styles from './PublicLayout.module.scss'

export const faqSectionID = 'faq'

type FAQSectionProps = {
  items?: StudyFaqItem[]
}

export default function FAQSection({ items }: FAQSectionProps) {
  const { gettext } = useTranslations()
  const faqItems = useMemo(
    () =>
      items?.sort((a, b) => a.display_order - b.display_order) || [
        {
          question: gettext(
            'How much does it cost to use Elixir AI for my research?'
          ),
          answer: gettext(
            "Elixir AI is completely free for paediatric doctors. Elixir collaborates with you in the project and shares any grant money, ensuring there's no upfront cost to you."
          ),
        },
        {
          question: gettext(
            'I have an idea for a study but no funding. Can I still use Elixir AI?'
          ),
          answer: gettext(
            'Absolutely! Our platform is designed to enable research without the need for traditional grant funding. You can start a pilot single site study quicker than you thought to obtain data. If you have a colleague with a similar interest in a different hospital, you have a multi-site study.'
          ),
        },
        {
          question: gettext(
            'How does Elixir AI help with regulatory compliance?'
          ),
          answer: gettext(
            'We do the heavy lifting with the required documents and our platform includes built-in compliance checks and automated processes to ensure your study meets all necessary regulatory requirements, significantly reducing the paperwork burden.'
          ),
        },
        {
          question: gettext(
            'How does Elixir AI support patient recruitment and consent?'
          ),
          answer: gettext(
            'Our digital recruitment and consent features allow you to easily reach potential participants and obtain informed consent through a secure, user-friendly interface.'
          ),
        },
        {
          question: gettext('Is the data collected through Elixir AI secure?'),
          answer: gettext(
            'Absolutely. We adhere to the highest standards of data security. Patient data is encrypted in transit and at rest. Elixir complies with all relevant data protection regulations, including GDPR.'
          ),
        },
        {
          question: gettext(
            'Can I collaborate with other researchers or institutions using Elixir AI?'
          ),
          answer: gettext(
            'Yes! Elixir AI facilitates collaboration between doctors, parents, and university or industry scientists, allowing you to expand the scope and impact of your research.'
          ),
        },
        {
          question: gettext('How does Elixir AI handle data analysis?'),
          answer: gettext(
            'Elixir could assist as much or as little as you need. If required we can provide powerful AI-driven analytics tools that can help you identify patterns, segment patients, and generate insights from the collected data.'
          ),
        },
        {
          question: gettext(
            'Can I use Elixir AI for post-market approval studies?'
          ),
          answer: gettext(
            'Yes, Elixir AI is ideal for post-market approval studies. We offer paid services for pharmaceutical and biotech companies specifically for this purpose.'
          ),
        },
        {
          question: gettext(
            'How does Elixir AI compare to traditional Contract Research Organizations (CROs)?'
          ),
          answer: gettext(
            'Unlike CROs, Elixir AI enables direct sponsorship of studies, reducing costs and delays associated with outsourced research. We specialise in paediatric trials and leverage advanced technology to enhance research processes.'
          ),
        },
      ],
    [items, gettext]
  )

  return (
    <div id={faqSectionID} className={styles.faq}>
      <div className={styles.faqContent}>
        <Typography variant="h4" color="primdary.dark" fontWeight={700} mb={5}>
          {gettext('Frequently Asked Questions')}
        </Typography>
      </div>
      {faqItems.map(({ question, answer }, index) => (
        <Fragment key={index}>
          {index !== 0 && <Divider />}
          <Accordion
            disableGutters
            elevation={0}
            sx={{
              py: 3,
              '&::before': {
                display: 'none',
              },
            }}
          >
            <AccordionSummary
              expandIcon={<AddIcon sx={{ color: 'primary.light' }} />}
              sx={{
                minHeight: 0,
                padding: 0,
                '& .MuiAccordionSummary-content': {
                  m: 0,
                },
                '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                  transform: 'rotate(45deg)',
                },
              }}
            >
              <Typography
                variant="subtitle1"
                color="primary.dark"
                fontWeight={600}
              >
                {question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ pt: 2, pb: 0, px: 0 }}>
              <Typography
                variant="body1"
                color="text.secondary"
                sx={{ whiteSpace: 'pre-wrap' }}
              >
                {answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Fragment>
      ))}
    </div>
  )
}
