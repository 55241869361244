import axios, { AxiosRequestConfig } from 'axios'
import { stringify } from 'qs'

const instance = axios.create({
  baseURL: import.meta.env.API_URL,
  paramsSerializer: (params) => stringify(params, { arrayFormat: 'repeat' }),
})

instance.interceptors.request.use((config) => {
  config.url = (config.url || '').replace(/\/?$/, '/')
  return config
})

instance.interceptors.response.use(
  (data) => data?.data,
  (err) => Promise.reject(err?.response || err?.request || err)
)

const api = <T, D = unknown>(config: AxiosRequestConfig<D>) =>
  instance.request<unknown, T>(config)
api.request = <T, D = unknown>(config: AxiosRequestConfig<D>) =>
  instance.request<unknown, T>(config)
api.get = <T, D = unknown>(url: string, config?: AxiosRequestConfig<D>) =>
  instance.get<unknown, T>(url, config)
api.delete = <T, D = unknown>(url: string, config?: AxiosRequestConfig<D>) =>
  instance.delete<unknown, T>(url, config)
api.options = <T, D = unknown>(url: string, config?: AxiosRequestConfig<D>) =>
  instance.options<unknown, T>(url, config)
api.post = <T, D = unknown>(
  url: string,
  data?: D,
  config?: AxiosRequestConfig<D>
) => instance.post<unknown, T, D>(url, data, config)
api.put = <T, D = unknown>(
  url: string,
  data?: D,
  config?: AxiosRequestConfig<D>
) => instance.put<unknown, T, D>(url, data, config)
api.patch = <T, D = unknown>(
  url: string,
  data?: D,
  config?: AxiosRequestConfig<D>
) => instance.patch<unknown, T, D>(url, data, config)

export { instance }

export default api
