import {
  closeSnackbar,
  MaterialDesignContent,
  SnackbarProvider,
  SnackbarProviderProps,
} from 'notistack'
import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'

import variables from 'assets/styles/_variables.module.scss'

const iconVariant = {
  success: <CheckIcon color="success" sx={{ mr: 1 }} />,
  error: <ErrorOutlineOutlinedIcon color="error" sx={{ mr: 1 }} />,
  warning: <WarningAmberOutlinedIcon color="warning" sx={{ mr: 1 }} />,
  info: <InfoOutlinedIcon color="info" sx={{ mr: 1 }} />,
}

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&&': {
    paddingLeft: 16,
    backgroundColor: variables.white,
    color: variables.textPrimary,
  },
}))

export default function Snackbar(props: SnackbarProviderProps) {
  return (
    <SnackbarProvider
      {...props}
      autoHideDuration={3000}
      maxSnack={3}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      action={(snackbarId) => (
        <IconButton size="small" onClick={() => closeSnackbar(snackbarId)}>
          <CloseIcon fontSize="small" />
        </IconButton>
      )}
      iconVariant={iconVariant}
      Components={{
        success: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent,
        warning: StyledMaterialDesignContent,
        info: StyledMaterialDesignContent,
      }}
    />
  )
}
